import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';

const TableExample = lazy(() => import('./components/TableExample/TableExample'));
const columns = [
  {
    Header: 'Name',
    accessor: 'first_name',
    style: {
      width: '40%',
    },
    customRender: (value, row) => `${value} ${row.last_name}`,
  },
  {
    Header: 'Birth date',
    accessor: 'date_of_birth',
    style: {
      width: '20%',
    },
  },
  {
    Header: 'City',
    accessor: 'address',
    style: {
      width: '20%',
    },
    customRender: (_, row) => row?.address?.city || 'Unknown',
  },
];

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/cross-imports-test"
          element={
            <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
              <TableExample
                text={'This is the original component from cross-imports-mfe'}
                onRow={(rowData: { first_name: string }) => {
                  return {
                    onClick: () => {
                      alert(
                        `You have just clicked the ${rowData.first_name} row from cross-imports-mfe!`
                      );
                    },
                  };
                }}
                columns={columns}
              />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
