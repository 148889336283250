import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import Router from './Router';
import { EnvProvider, EnvConfig } from './components';
import { IntlProvider } from './i18n';

export default function App(props: EnvConfig) {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-cross-imports-test-mfe',
    seed: 'bees-hub-cross-imports-test-mfe',
  });

  return (
    <EnvProvider env={props}>
      <StylesProvider generateClassName={generateClassName}>
        <IntlProvider>
          <Router />
        </IntlProvider>
      </StylesProvider>
    </EnvProvider>
  );
}
