import { Button as HexaUIButton } from '@hexa-ui/components';

const Button = ({
  text,
  onClick,
}: {
  text: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}): JSX.Element => {
  return (
    // eslint-disable-next-line no-console
    <HexaUIButton onClick={onClick}>{text || 'From External!'}</HexaUIButton>
  );
};

export default Button;
