import { Grid, Heading, Table, TableProps } from '@hexa-ui/components';
import axios from 'axios';
import * as React from 'react';

interface User {
  first_name: string;
  last_name: string;
  address: {
    city: string;
  };
  birth_date: string;
}

const TableExample = ({
  text,
  onRow,
  columns,
}: {
  text: string;
  onRow: TableProps<unknown>['onRow'];
  columns: TableProps<unknown>['columns'];
}) => {
  const [data, setData] = React.useState<User[]>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    async function getRandomUser() {
      try {
        setLoading(true);
        const { data }: { data: User[] } = await axios.get(
          'https://random-data-api.com/api/v2/users?size=20'
        );
        setData(data);
      } catch (err) {
        console.log('error: ', err);
      } finally {
        setLoading(false);
      }
    }

    getRandomUser();
  }, []);

  return (
    <Grid.Container>
      <Grid.Item xs={12}>
        <Heading size="H4" css={{ margin: '24px 0' }}>
          {text}
        </Heading>
      </Grid.Item>
      <Grid.Item xs={12}>
        <Table columns={columns} data={data} loading={loading} onRow={onRow} />
      </Grid.Item>
    </Grid.Container>
  );
};

export default TableExample;
